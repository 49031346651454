import React from 'react';
import './overlay.scss';

export default class Overlay extends React.Component {
    state = {}

    constructor(props) {
        super(props)
        this.state = {
            hidden: props.hidden
        }
    }

    handleClose() {
        this.setState({hidden: true})
        setTimeout(() => {
            this.props.onClose()
        }, 350)
    }

    componentWillReceiveProps(newProps) {
        this.setState({hidden: newProps.hidden})
    }

    render() {
        const props = this.props
        const classNames = ['overlay'];

        if (this.state.hidden) {
            classNames.push('overlay-hidden')            
        }

        const contentStyles = {
            justifyContent: props.justifyContent,
            alignItems: props.alignItems
        }

        return (
            <div className={classNames.join(' ')}>
                <div className="overlay-content" style={contentStyles}>
                    {props.children}
                </div>
                <button type="button" className="overlay-close" onClick={() => this.handleClose()}>
                    <svg className="overlay-close-image" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                            <g transform="translate(-73.000000, -48.000000)" stroke="#FFFFFF" strokeWidth="2">
                                <g id="Group" transform="translate(75.000000, 50.000000)">
                                    <path className="roto" d="M0,0 L28.2842712,28.2842712" id="Line"></path>
                                    <path className="roto" d="M0,28.2842712 L28.2842712,0" id="Line-Copy"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span className="overlay-close-text">Close</span>
                </button>
            </div>
        )
    }
}
