export function fetchFrontPageImages()  {
    return fetch(API_URL + '/tables/frontpage_image/rows?depth=2&columns=id,left,left_mobile,right,right_mobile,left_project,right_project')       
}

export function fetchCategories()  {
    return fetch(API_URL + '/tables/category/rows?depth=2&columns=id,frontpage_image,slug,name,theme')       
}

export function fetchProjects(secret)  {
    if (secret) {
        return fetch(API_URL + '/tables/project/rows?filters[password][eq]='+ secret +'&depth=2&columns=id,sort,slug,name,category,year,type,images,description')
    }
    return fetch(API_URL + '/tables/project/rows?filters[password][null]=1&depth=2&columns=id,sort,slug,name,category,year,type,images,description')       
}

export function categoriesError(error) {
    return {
        type: 'CATEGORIES_ERROR',
        error: error
    }
}

function loadedCategories(categories) {
    return {
        type: 'CATEGORIES_LOADED',
        categories: categories
    }
}

export function load() {
    return (dispatch) => {
        fetchFrontPageImages() .then(
            response => {
                response.json().then(data => {
                    dispatch({
                        type: 'FRONTPAGE_IMAGES_LOADED',
                        frontpageImages: data.data
                    })
                }) 
            },
            error => dispatch(categoriesError('Startseitenbilder konnten nicht geladen werden'))
        )

        fetchCategories().then(
            response => {
                response.json().then(data => {
                    dispatch({
                        type: 'CATEGORIES_LOADED',
                        categories: data.data
                    })
                }) 
            },
            error => dispatch(categoriesError('Kategorien konnten nicht geladen werden'))
        )

        fetchProjects().then(
            response => {
                response.json().then(data => {
                    dispatch({
                        type: 'PROJECTS_LOADED',
                        projects: data.data
                    })
                }) 
            },
            error => dispatch(categoriesError('Projekte konnten nicht geladen werden'))
        )
    }
}

