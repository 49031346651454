import React from 'react'

import Image from './Image.jsx'

export default class About extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        return fetch(API_URL + '/tables/about/rows?depth=2&columns=title,text,image')
            .then(r => r.json())
            .then(data => {
                const about = data.data[0];
                this.setState({
                    ...about 
                })
            });
    }

    render() {
        const image = this.state.image


        return <div className="about pages-item">
            <div className="strip">
                <div className="strip-item strip-item--end">
                    <div className="content content-text">
                        <h1>{this.state.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: this.state.text}} />
                    </div>
                </div>
                <div className="strip-item">
                    <div className="content content-image">
                        <div className="image">
                            {image && (
                            <img className="image-element" style={{"visibility": "visible"}} src={CMS_URL + image.data.url} height="600" 
                                onDrag={(e) => { e.preventDefault(); return false;}} 
                                onDragStart={(e) => { e.preventDefault(); return false;}}  
                                onContextMenu={(e) => { e.preventDefault(); return false;}}  
                                galleryimg="no" 
                                onMouseDown={(e) => { e.preventDefault(); return false;}} 
                            />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}