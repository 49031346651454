export default function images(state = {}, action) {
    switch(action.type) {
        case 'FRONTPAGE_IMAGES_LOADED':
            const frontpageImages = {}
            const image = {}

            for (var frontpageImageIndex = 0; frontpageImageIndex < action.frontpageImages.length; frontpageImageIndex++) {
                const image = action.frontpageImages[frontpageImageIndex]

                frontpageImages[image.left.data.id] = image.left.data
                frontpageImages[image.right.data.id] = image.right.data
                if (image.left_mobile)
                    frontpageImages[image.left_mobile.data.id] = image.left_mobile.data
                if (image.right_mobile)
                    frontpageImages[image.right_mobile.data.id] = image.right_mobile.data                
            }

            return {
                ...state,
                ...frontpageImages
            }

        case 'PROJECTS_LOADED':
            const images = {}
            for (var projectIndex = 0; projectIndex < action.projects.length; projectIndex++) {
                const project = action.projects[projectIndex]
                for (var imageIndex = 0; imageIndex < project.images.data.length; imageIndex++) {
                    const image = project.images.data[imageIndex]
                    images[image.id] = image
                }
            }

            return {
                ...state,
                ...images
            }
  }

  return state;
}