import { combineReducers } from 'redux'
import site from './site'
import projects from './projects'
import categories from './categories'
import images from './images'
import frontpageImages from './frontpage_images'

export default combineReducers({
    site,
    projects,
    images,
    categories,
    frontpageImages
})