import React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { withRouter, Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import Image from './Image'
import Overlay from './Overlay'

class Project extends React.Component {
    state = {}

    stripItemMouseOver(groupId) {
      this.setState({groupId: groupId});
    }

    resetStripItemMouseOver() {
      this.setState({groupId: null});
    }

    render() {
      const props = this.props;
      if (!props.project) return null

      const images = props.project.images.data.slice()

      for (var i = images.length - 1; i >= 0; i--) {
        const image = images[i]
        const junc = props.project.images.junction.data.filter(j => j.image == image.id)[0]
        image.sort = junc.sort 
        image.image_text = junc.image_text 
        image.image_group = junc.image_group
        image.youtube_id = junc.youtube_id
      }

      images.sort((a,b) => a.sort < b.sort ? -1 : 1);

      var sortedImages = []
      let image = null;
      var imageGroupId = 1;
      while(image = images.shift()) {
        sortedImages.push(image);
        image.first = true;
        image.last = true;
        if (!image.image_group) {
          continue;
        }

        image.groupId = imageGroupId;

        for (var i = 0; i < images.length; i++) {
          if (image.image_group == images[i].image_group) {
            image.last = false;
            images[i].last = true;
            images[i].groupId = image.groupId;
            images[i].image_text = image.image_text;
            sortedImages.push(images[i]);
            images.splice(i, 1);
            i--;
          }
        }

        imageGroupId++;
      }

      return <div className="project pages-item">
          <div className="strip">
            <div className="strip-item strip-item--end">
               <div className="content">
                  <h1 className="content-title">{props.project.name}</h1>
                  <div className="content-text"dangerouslySetInnerHTML={{__html: props.project.description}} />
                  <p className="meta">
                     {props.project.type}
                  </p>      
               </div>
            </div>
            {sortedImages.map((image, i) => {
              const classNames = ["strip-item", "strip-item--stretch"]
              if (image.image_group && !image.last) {
                classNames.push("strip-item--merge")
              }

              if(image.image_group && image.first) {
                classNames.push("strip-item--merge-first")
              }

              if(image.image_group && image.last) {
                classNames.push("strip-item--merge-last")
              }

              if(image.groupId === this.state.groupId) {
                classNames.push("strip-item--active");
              }

            return <div key={i} className={classNames.join(' ')} onMouseOver={() => this.stripItemMouseOver(image.groupId)} onMouseOut={() => this.resetStripItemMouseOver()}>
               <Image key={image.id} id={image.id} onClick={() => image.youtube_id && props.gotoVideo(image.youtube_id)} />
            </div>  
            })}
            
            <div className="strip-item strip-item--center">
               <div className="content">
                  {props.nextProject && (
                  <Link to={'/category/' + props.nextProject.category.data.slug + '/' + props.nextProject.slug} className="next-project-button next-project-button-desktop pull-right">
                    <span className="link-text">{props.nextProject.name}</span>
                    <svg className="link-arrow" version="1.1" x="0px" y="0px" viewBox="-5 -5 90 75">
                      <polyline className="tip changeable" fill="none" stroke="#000000" strokeWidth="5" points="47.2,0 79.7,32.5 47.2,65 "/>
                      <g>
                        <line className="handle changeable" stroke="#000000" strokeWidth="5" x1="78.8" y1="32.5" x2="0" y2="32.5"/>
                      </g>
                    </svg>            
                    </Link>
                  )}

                  {props.prevProject && (
                  <Link to={'/category/' + props.prevProject.category.data.slug + '/' + props.prevProject.slug} className="prev-project-button prev-project-button-mobile pull-right">
                    <svg className="link-arrow" version="1.1" x="0px" y="0px" viewBox="-5 -5 90 75">
                      <polyline className="tip changeable" fill="none" stroke="#000000" strokeWidth="5" points="47.2,0 79.7,32.5 47.2,65 "/>
                      <g>
                        <line className="handle changeable" stroke="#000000" strokeWidth="5" x1="78.8" y1="32.5" x2="0" y2="32.5"/>
                      </g>
                    </svg> 
                  </Link>
                  )}
                  {props.nextProject && (
                  <Link to={'/category/' + props.nextProject.category.data.slug + '/' + props.nextProject.slug} className="next-project-button next-project-button-mobile pull-right">
                    <svg className="link-arrow" version="1.1" x="0px" y="0px" viewBox="-5 -5 90 75">
                      <polyline className="tip changeable" fill="none" stroke="#000000" strokeWidth="5" points="47.2,0 79.7,32.5 47.2,65 "/>
                      <g>
                        <line className="handle changeable" stroke="#000000" strokeWidth="5" x1="78.8" y1="32.5" x2="0" y2="32.5"/>
                      </g>
                    </svg> 
                  </Link>
                  )}
               </div>
            </div>
         </div>
      </div>
  }
}

export default withRouter(connect(
   (state, props) => {      
        const categoryName = props.match.params.category;
        const projectName = props.match.params.project;
          
        const categoryProjects = state.projects.filter(p => p.category.data.slug == categoryName)

        const project = categoryProjects.filter(p => p.slug === projectName)[0];

        let nextProject = null
        let prevProject = null

        if (project) {
          const projectIndex = state.projects.indexOf(project)
          nextProject = state.projects[(projectIndex+1) % state.projects.length]
          prevProject = state.projects[(projectIndex-1) % state.projects.length]
        }

        return {
            project: project,
            nextProject: nextProject,
            prevProject: prevProject
        }
   },

   (dispatch, props) => {
        return {
            gotoVideo: (youtube_id) => dispatch(push('/video/' + youtube_id)), 
            loadProject: () => dispatch(actions.projects.loadProject(props.projectName))
        }
   }
)(Project));

