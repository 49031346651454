import React from 'react'
import Overlay from './Overlay'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import Image from './Image'

const ProjectImageOverlay = (props) => {
    return (
        <Overlay hidden={!props.selectedImage} onClose={props.close}>
          {props.selectedImage && (
            <Image key={props.selectedImage.id} id={props.selectedImage.id} />
          )}
       </Overlay>
    )
}

export default withRouter(connect(
    (state, props) => {      
        const selectedImageId = props.match.params.image;
        const selectedImage = state.images[selectedImageId]

        return {
            selectedImage
        }
    },

    (dispatch, props) => {
        const params = props.match.params;
        return {
            close: () => dispatch(push('/category/'+params.category+'/'+params.project))
        }
    },

)(ProjectImageOverlay));