import React from 'react';
import './Site.scss';
import { connect } from 'react-redux';
//import {  } from 'react-router';
import { NavLink as Link, withRouter } from 'react-router-dom'

import menuToggle from './../imgs/menu-toggle.svg';

import {addWheelListener} from 'wheel';

import Nav from './Nav'

class Site extends React.Component {
   constructor(props) {
      super(props)
      this.state = {theme: 'white'}
   }

   componentDidMount() {
      addWheelListener(this.root, (e) => {
         if (window.innerWidth <= 900)
           return;
         e.preventDefault();
         this.root.scrollLeft += e.deltaY + e.deltaX;
         this.handleScroll()
      })

      if (!this.state.isLoaded) {
         setTimeout(() => {
            this.setState({isLoaded: true})
         }, 100)
      }

      this.updateTheme(this.props)

      window.addEventListener("resize", () => {
        this.handleScroll()
        this.forceUpdate()
      });
   }

   handleScroll() {
      if(this.root.scrollLeft > 0 || this.root.scrollTop > 0) {
        if (this.root.className.indexOf("is-scrolled") < 0) {
          this.root.className += " is-scrolled"
        }
      } else {
        this.root.className = this.root.className.replace('is-scrolled', '');
      }
   }


   updateTheme(props) {
     const categoryRegex = /\/category\/(\w+)\/?/
     const match = categoryRegex.exec(props.location.pathname)
     const categories = props.categories;
     if (match) {
       const categorySlug = match[1]
       const category = categories.filter(c => c.slug === categorySlug)[0]
       if (category) {
         this.setState({theme: category.theme})
       }
       return;
     }
     
     
     this.setState({theme: 'white'})
   }

   componentWillReceiveProps(nextProps) {
      this.updateTheme(nextProps)
   }

   render() {
      const smallScreen = window.innerWidth <= 900 || window.innerHeight <= 800
      const classNames = ["site", "site-" + this.state.theme];

      if (this.state.isLoaded)
         classNames.push("site-is-loaded");

      if (this.props.isInverted)
         classNames.push("is-inverted");


      const navClassNames = ["site-nav"]
      const footerClassNames = ["site-footer"]
      const headerClassNames = ["site-footer"]

      const styles = {
         color: '#' + this.props.theme.foreground,
         backgroundColor: '#' + this.props.theme.background
      }

      return (
         <div id="site" ref={(elem) => {this.root = elem}} className={classNames.join(' ')} style={styles} onScroll={(e) => this.handleScroll(e)}>
            <header className={"site-header"}>
               <h1 className="site-header-title"><Link to="/"><span className="word">Fabien Holzer</span> <br /><span className="word">Fotografie</span></Link></h1>
            </header>
            <Nav />
            <main className="site-main">
               {this.props.children}
            </main>
            {!smallScreen && (
            <footer className="site-footer">
               <nav className="site-footer-nav">
                  <Link to="/imprint" className="site-footer-nav-link" href="#">Impressum + Datenschutz</Link>
               </nav>
            </footer>         
            )} 
         </div>
      );
   }
}
export default withRouter(connect(
   (state, props) => { 
      const categoryName = props.match.params.category;
      const projects = state.projects.filter(p => p.category.data.name === categoryName)

      return {
         isScrolled: state.site.isScrolled,
         isInverted: state.site.isInverted,
         categories: state.categories || [],
         projects: projects,
         routing: state.routing,
         categoryName: categoryName,
         theme: state.site.theme,
      }
   },

   (dispatch, props) => {
      return {

      }
   }
)(Site));