import React from 'react'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        return fetch(API_URL + '/tables/contact/rows?depth=2&columns=text,social_media')
            .then(r => r.json())
            .then(data => {
                const contact = data.data[0];
                this.setState({
                    ...contact 
                })
            });
    }

    render() {
        return <div className="contact pages-item">
            <div className="strip">
                <div className="strip-item strip-item--end">
                    <div className="content content-text">
                        <div dangerouslySetInnerHTML={{__html: this.state.text }} />
                    </div>
                </div>
                <div className="strip-item strip-item--end">
                    <div className="content content-text">
                        <div dangerouslySetInnerHTML={{__html: this.state.social_media }} />
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Contact;