import React from 'react'
import Overlay from './Overlay'
import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import './Image.scss';

class Image extends React.Component {
    state = {}

    componentDidMount() {
        this.refs.img.addEventListener("load", () => {
            setTimeout(() => this.setState({isLoaded: true}), 100)
        });

        this.refs.srcImg.addEventListener("load", () => {
            setTimeout(() => this.setState({isLoaded: true}), 100)
        });
    }

    render () {
        const smallScreen = window.innerWidth <= 900 || window.innerHeight <= 814
        const props = this.props
        const classNames = ["image"]

        if(props.onClick) {
            classNames.push('image-is-clickable')
        }

        if (this.state.isLoaded)
            classNames.push("image-is-loaded")
        else
            classNames.push('image-is-loading')

        const minWidth = !props.ignoreMinWidth && !smallScreen && (600 / props.image.height * props.image.width)
        
        return (
            <div className={classNames.join(' ')}>
                <div className="image-element-container" onClick={props.onClick}>
                    {props.image && (
                    <picture ref="img"
                            alt={props.image.caption} 
                            style={
                                {minWidth: minWidth}
                            }
                            onMouseDown={(e) => { e.preventDefault(); return false;}} 
                            onDrag={(e) => { e.preventDefault(); return false;}} 
                            onDragStart={(e) => { e.preventDefault(); return false;}}  
                            onContextMenu={(e) => { e.preventDefault(); return false;}} galleryimg="no" >
                        {props.mobileImage && (
                        <source media="(max-width: 768px)" srcSet={CMS_URL + props.mobileImage.url}  />
                        )}
                        <img ref="srcImg" className="image-element" src={CMS_URL + props.image.url} />           
                    </picture>
                    )} 
                    <span className="image-loader" />
                    {props.image.image_text && (
                    <span className="image-text">
                        <span className="image-text-value">{props.image.image_text}</span>
                    </span>
                    )}
                </div>
            </div>
        )
    }
}

export default connect(
    (state, props) => {
        const image = state.images[props.id]
        const mobileImage = state.images[props.mobileId]
        return {
            image: image,
            mobileImage: mobileImage
        }
    },

    (dispatch, props) => {
        return {
            
        }
    },

)(Image);