import React from 'react'
import { connect } from 'react-redux';
import actions from '../actions';
import { withRouter, Link } from 'react-router-dom'
import { push } from 'connected-react-router'

import Image from './Image';

class Home extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            frontpageImage: props.frontpageImage
        }
    }

    render() {
        const props = this.props;
        const frontpageImage = props.frontpageImages[this.state.frontpageImage]
        if (!frontpageImage) return <div />
        
        return (
            <div className="home pages-item">
                <div className="strip">
                    <div className="strip-item">
                        <div className="content">
                            <div className="home-slide">
                                <span className="home-slide-link">
                                    <Image id={frontpageImage.left.data.id} mobileId={frontpageImage.left_mobile && frontpageImage.left_mobile.data.id} ignoreMinWidth={true} onClick={() => props.handleImageClick(frontpageImage.left_project)} />
                                    <span className="home-slide-title">corporate</span>      
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="strip-item">
                        <div className="content">
                            <div className="home-slide">
                                <span className="home-slide-link">
                                    <Image id={frontpageImage.right.data.id} mobileId={frontpageImage.right_mobile && frontpageImage.right_mobile.data.id} ignoreMinWidth={true} onClick={() => props.handleImageClick(frontpageImage.right_project)} />
                                    <span className="home-slide-title">editorial</span>      
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(connect(
    (state, props) => {
        return {
            frontpageImages: state.frontpageImages.images,
            frontpageImage: state.frontpageImages.index
        }
    }, 
    (dispatch, props) => {
        return {
            handleImageClick: (project) => {
                dispatch(push('/category/' + project.data.category.data.slug + '/' + project.data.slug))
            }
        }
    })
    (Home)
);


