import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import { Router, Route, Switch } from 'react-router'
import { createBrowserHistory } from 'history'

import Category from './components/Category'
import Project from './components/Project'
import About from './components/About'
import Home from './components/Home'
import Contact from './components/Contact'
import Imprint from './components/Imprint'

import { load } from './actions'
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

import Site from './components/Site';
import Overlay from './components/Overlay';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import ProjectImageOverlay from './components/ProjectImageOverlay';
import PageOverlay from './components/PageOverlay';
import VideoOverlay from './components/VideoOverlay';

const history = createBrowserHistory()

const store = createStore(
  connectRouter(history)(rootReducer),
  applyMiddleware(
    thunk,
    routerMiddleware(history)
  )
);

store.dispatch(load())

function resetScroll() {
  const site = document.getElementById("site")
  const scrollDuration = 100
  const scrollX = site.scrollX
  const scrollStep = Math.PI / ( scrollDuration / 15 )
  const cosParameter = scrollX / 2

  var scrollCount = 0
  var scrollMargin = 0

  var scrollInterval = setInterval( function() {
      if ( site.scrollX <= 1) {
        return;
      }

      scrollCount = scrollCount + 1;  
      scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );

      site.scrollTo(( scrollX - scrollMargin ), 0);
      
      clearInterval(scrollInterval); 
  }, 15 );
}

history.listen((location, action) => {
  resetScroll()
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route render={({location}) => {
        return (
        <div className="page">
          <Site>
            <TransitionGroup className="pages">
              <CSSTransition key={location.key} classNames="fade" timeout={600}>
                <Switch location={location}>
                    <Route exact path="/category/:category/:project" component={Project} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/contact/" component={Contact} />
                    <Route exact path="/" component={Home} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>            
          </Site>
          <Switch>
            <Route 
              path="/video/:youtube_id?"
              component={VideoOverlay}
            />
            <Route 
              path="/:page?"
              component={PageOverlay}
            />
          </Switch>
        </div>
      )}} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);