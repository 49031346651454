const initialState = {
    images: [],
    index: 0
}

export default function images(state = initialState, action) {
  switch(action.type) {
    case 'FRONTPAGE_IMAGES_LOADED':
      return {
        images: action.frontpageImages,
        index: Math.floor(Math.random()*action.frontpageImages.length)
      }

    case '@@router/LOCATION_CHANGE':
    case 'FRONTPAGE_IMAGES_LOADED':
      return {
        ...state,
        index: Math.floor(Math.random()*state.images.length)
      }
  }

  return state;
}