import React from 'react'
import Overlay from './Overlay'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { push, goBack } from 'connected-react-router'

const VideoOverlay = (props) => {
    return (
        <Overlay hidden={!props.match.params.youtube_id} onClose={props.close}>
            <div className="content">
                <div class="video-container">
                    <iframe type="text/html" width="640" height="360"
  src={"https://www.youtube.com/embed/"+ props.match.params.youtube_id +"?autoplay=1&controls=2&modestbranding=1&playsinline=1&rel=0&origin=http://fabienholzer.com"}
  frameBorder="0"/>
                </div>
            </div>
        </Overlay>
    )
}

export default withRouter(connect(
    (state, props) => {      
        return {}
    },

    (dispatch, props) => {
        const params = props.match.params;
        return {
            close: () => dispatch(goBack())
        }
    },

)(VideoOverlay));