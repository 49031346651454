import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { NavLink as Link, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { push } from 'connected-react-router'

export class NavComponent extends React.Component {
   state = {}

   componentDidMount() {
      window.addEventListener("resize", () => {
         this.forceUpdate()
      });

      document.addEventListener("mousedown", (e) => this.checkOutsideClick(e), false);
   }   

   checkOutsideClick(e) {
      console.log(this.refs.nav, e.target);
      if(!this.refs.nav.contains(e.target)) {
         this.setState({selectedCategoryId: null})
      }
   }

   selectCategory(category) {
      this.setState({
         selectedCategoryId: category && category.id
      })
   }

   renderProjects(id) {
      let projects = this.props.projects.filter(i => i.category.data.id == id)
      projects.sort((a,b) => a.sort > b.sort ? -1 : 1)
      projects = projects.map((item, i) => (
         <Link key={i} to={'/category/' + item.category.data.slug + '/' + item.slug} key={item.name} className="site-nav-link site-nav-sublink" onClick={() => {
            this.setState({navOpen: false});
            this.selectCategory(null);
         }}>{item.name}</Link>
      ))


      var groups = []

      while(projects.length > 0) {
         if (groups.length == 0 || groups[groups.length-1].length >= 3)
            groups.push([])

         groups[groups.length-1].push(projects.pop());
      }

      return groups.map((g, i) => {
         return (
            <div key={i} className="link-group">
               {g}
            </div>
         )
      })
   }



   render() {
      console.log('render');
      const smallScreen = window.innerWidth <= 900 || window.innerHeight <= 800
      const projectsRendering = this.renderProjects(this.state.selectedCategoryId)
      return (
         <nav className={"site-nav" + (this.state.navOpen?' open':'')} ref="nav">
            <h1 className="site-nav-title" onClick={() => this.setState({navOpen: true})}>Menu</h1>
            <div className="site-nav-link-container">
                           <h1 className="site-nav-close" onClick={() => this.setState({navOpen: false})}>Close</h1>
               <div className="site-nav-column">
                  {this.props.categories.map(item => (
                     <div key={item.name}>
                     <span 
                        className={"site-nav-link site-nav-toplink" + (this.state.selectedCategoryId==item.id?" active":"")} 
                        onClick={() => {
                           this.selectCategory(item)
                        }}
                        >{item.name}
                     </span>

                     {smallScreen && (
                        <div className={["site-nav-sublinks", item.id==this.state.selectedCategoryId?"selected":""].join(" ")}>
                        {this.renderProjects(item.id)}
                        </div>
                     )}
                     </div>
                  ))}
                  <Link to="/about" className="site-nav-link  site-nav-toplink" onClick={(e) => {
                     e.preventDefault()
                     this.setState({navOpen: false})
                     this.selectCategory(null)
                     this.props.nav("/about")
                  }}>about</Link>
                  <Link to="/contact" className="site-nav-link  site-nav-toplink" onClick={(e) => {
                     e.preventDefault()
                     this.setState({navOpen: false})
                     this.selectCategory(null)
                     this.props.nav("/contact")
                  }}>contact</Link>
                  {smallScreen && (
                  <Link to="/imprint" className="site-nav-link site-nav-toplink" href="#" onClick={() => this.setState({navOpen: false})}>impressum + datenschutz</Link>
                  )}
               </div>
               {!smallScreen && (
                  <div className={["site-nav-column site-nav-column-sub", projectsRendering.length==0?"site-nav-column-empty":""].join(" ")}>
                  <TransitionGroup>
                     <CSSTransition appear={true} key={this.state.selectedCategoryId} classNames="fade" timeout={250}>
                        <div key={this.state.selectedCategoryId} className="link-group-container">
                           {projectsRendering}
                        </div>
                     </CSSTransition>
                  </TransitionGroup>
                  </div>
               )}
            </div>
         </nav>
      )
   }
}

export default withRouter(connect(
   (state, props) => {
      return {
         categories: state.categories,
         projects: state.projects,
      }
   },
   (dispatch, props) => {
      return {
         nav: (to) => dispatch(push(to))
      }
   }
)(NavComponent))