const initialState = {
    isScrolled: false,
    isInverted: false,
    theme: 'white'
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'SITE_THEME':
            return {
                ...state,
                theme: action.theme
            }

        case 'SITE_INVERT':
            return {
                ...state,
                isInverted: action.isInverted
            }
    }

    return state;
}